<template>
  <el-container class="shopList ">
    <el-header class="box add">
       <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>个人中心</el-breadcrumb-item>
       <el-breadcrumb-item>更新密码</el-breadcrumb-item>
    </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt15">
     <el-form :inline="true"  status-icon ref="form2"  :model="form2"  class="demo-ruleForm">
     <el-row class="box mt20  f14">
          <el-col :span="11" class="clear" >
        <el-form-item  label="旧密码：" size="medium" class="w" label-width="28%"  prop="user_loginpass_old" :rules="[{required: true,message: '请输入旧密码', trigger: 'blur' },{ pattern: /^[A-Za-z0-9]+$/, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'},{ min: 4, max: 15, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'}]">
           <el-input type="password" placeholder="旧密码" minlength="4" maxlength='15' clearable v-model="form2.user_loginpass_old" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
          <el-col :span="11" class="clear" >
        <el-form-item  label="新密码：" size="medium" class="w" label-width="28%"  prop="user_loginpass"  :rules="[{required: true,message: '请输入新密码', trigger: 'blur' },{ pattern: /^[A-Za-z0-9]+$/, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'},{ min: 4, max: 15, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'}]">
           <el-input type="password" placeholder="新密码" minlength="4" maxlength='15' clearable v-model="form2.user_loginpass" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="11" class="clear" >
        <el-form-item  label="确认密码：" size="medium" class="w" label-width="28%"  prop="user_loginpass_re"  :rules="[{required: true,message: '请再次输入新密码', trigger: 'blur' },{ pattern: /^[A-Za-z0-9]+$/, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'},{ min: 4, max: 15, message: '请输入密码,长度4-15位数字或字母组成',trigger: 'blur'}]">
           <el-input type="password" placeholder="确认密码" minlength="4" maxlength='15' clearable v-model="form2.user_loginpass_re" class="input-with-select">
            </el-input>
        </el-form-item>
        </el-col>
        <el-col :span="22" class="tl" >
        <el-button   class="ml_5"  size="medium" type="primary" @click="submitForm1('form2')">提交</el-button>
        </el-col>
     </el-row>
        </el-form>  
</el-main>
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { local,aa,objKeySort } from '../../../util/util'
import md5 from 'js-md5';
export default {
  data () {
    return {
      user:'',
      loading:false,
      tableData:[],
      show: true,
      count: '',
      form2:{
        user_loginpass_old:'',
        user_loginpass:'',
        user_loginpass_re:''
      },
     
    }
  },
   created () {
     var that=this;
      that.user = local.get('user');
  },
  methods: {
      submitForm1(form2){
       
        let params={
          user_loginpass_old:md5(this.form2.user_loginpass_old),
          user_loginpass:md5(this.form2.user_loginpass),
          user_loginpass_re:md5(this.form2.user_loginpass_re),
        }
         this.$refs[form2].validate((valid) => {
        if (valid) {    
           if(this.form2.user_loginpass!=this.form2.user_loginpass_re){
        this.$message.error("两次输入新密码不一致，请重新输入");
            return false;
      }
           axios.put('/pc/user/password/update', params).then((v) => {
            }) 
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
         })
      },
}
}
</script>
<style lang="scss" scoped>
.shopList{
  overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
    .active {
       background: #2EC68A;
    border-color: #2EC68A;
    color: #FFFFFF;
  }
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}

}

</style>
